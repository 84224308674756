<template>
  <div class="myEntrustChild">
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      ref="list"
      :finishedText="finishedText"
      :commonOperaParams="commonOperaParams"
    >
      <div class="item" v-for="(item, index) in listData" :key="index">
        <div class="oItem first">
          <div class="oItem-l"></div>
          <div :class="['oItem-r', 'te', returnClass(item.status)]">
            {{ appointStatusArrMap[item.status] }}
          </div>
        </div>
        <div class="oItem">
          <div class="oItem-l">家长姓名</div>
          <div class="oItem-r">{{ item.parentsName }}</div>
        </div>
        <div class="oItem">
          <div class="oItem-l">家长电话</div>
          <div class="oItem-r">{{ item.parentsPhone }}</div>
        </div>
        <div class="oItem">
          <div class="oItem-l">孩子姓名</div>
          <div class="oItem-r">{{ item.childName }}</div>
        </div>
        <div class="oItem">
          <div class="oItem-l">托幼时间</div>
          <div class="oItem-r te">{{ item.dayName }} {{ item.timeDuring }}</div>
        </div>
        <div class="oItem">
          <div class="oItem-l">课程内容</div>
          <div class="oItem-r te" @click="lookCourse(item)">查看课程</div>
        </div>

        <div class="oItem" v-show="item.grade">
          <div class="oItem-l">评价打分</div>
          <div class="oItem-r">{{ scoreMap[item.grade] }}</div>
        </div>

        <div
          class="oItem flex"
          v-show="item.feedbackPicture && item.feedbackPicture.length > 0"
        >
          <div class="oItem-l">图片</div>
          <div class="oItem-r">
            <pic-list
              :picList="item.feedbackPicture.split(',') || []"
            ></pic-list>
          </div>
        </div>

        <div class="oItem flex" v-show="item.feedbacksSuggestion">
          <div class="oItem-l">反馈内容</div>
          <div class="oItem-r">
            {{ item.feedbacksSuggestion }}
          </div>
        </div>
      </div>
    </v-list>
    <div class="courseDialog" v-if="isCourseDialog">
      <div class="courseDialog-c">
        <div class="courseDialog-t">
          <div class="course-t">
            {{ current.dayName }} {{ current.timeDuring }}
          </div>
          <div class="course-b">
            <div
              class="list"
              v-for="(item, index) in postData"
              :key="index"
              v-show="item.timeDuring == current.timeDuring"
            >
              <div class="list-l">
                <div class="list-l-c">
                  <p>{{ item.activityName }}</p>
                  <h3>{{ item.activityTime }}</h3>
                </div>
              </div>
              <div class="list-r">{{ item.activityDetail }}</div>
            </div>
          </div>
        </div>
        <div class="courseDialog-b" @click="close">
          <img src="./img/close.png" alt="" />
        </div>
      </div>
    </div>

    <v-dialog v-model="isDialog" title="请选择" @confirm="confirm">
      <v-radio-group
        @change="change"
        :radioObj="radioObj"
        v-model="form.applyStatus"
      ></v-radio-group>
    </v-dialog>
  </div>
</template>

<script>
import {
  selectMyApplyUrl,
  handelStatusUrl,
  getClassDataByDayUrl,
} from "./api.js";
import { appointStatusArrMap } from "./map.js";
import picList from "@/components/bussiness/picList";
var moment = require("moment");

export default {
  name: "myEntrustChild",
  components: {
    picList,
  },
  data() {
    return {
      current: {},
      postData: [],
      amList: [],
      pmList: [],
      appointStatusArrMap,
      radioObj: [
        { name: "1", value: "通过" },
        { name: "2", value: "拒绝" },
      ],
      scoreMap: {
        1: "非常差",
        2: "差",
        3: "一般",
        4: "满意",
        5: "非常满意",
      },
      isDialog: false,
      finishedText: "没有更多了",
      finished: false,
      isCourseDialog: false,
      commonOperaParams: {
        showMy: false,
        isCommonOpera: false,
      },
      listData: [],
      form: {
        applyStatus: "1",
      },
      id: "",
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    openId() {
      return this.$store.state.openId;
    },
  },
  mounted() {},
  methods: {
    close() {
      this.isCourseDialog = false;
    },
    lookCourse(item) {
      this.current = item;
      this.isCourseDialog = true;
      this.postData = [];
      if (item.dayName) {
        this.getPartyList(item.dayName);
      }
    },
    async getPartyList(dayData) {
      let params = {
        userId: this.userId,
        dayData: dayData,
        tenantId: this.tenantId,
      };
      let res = await this.$axios.get(getClassDataByDayUrl, { params });
      if (res.code === 200) {
        this.postData = res.data;
        this.postData.forEach((ele) => {
          if (ele.timeDuring == "上午") {
            this.amList.push(ele);
          }
          if (ele.timeDuring == "下午") {
            this.pmList.push(ele);
          }
        });
      }
    },
    returnClass(data) {
      if (data == 0) {
        return "yellow";
      } else if (data == 1) {
        return "blue";
      } else if (data == 2) {
        return "grey";
      }
    },
    change(value) {
      // console.log(value);
      this.form.applyStatus = value;
    },
    async handelStatusUrl() {
      let _this = this;
      let params = {
        applyId: _this.id,
        applyStatus: _this.form.applyStatus,
      };
      let res = await this.$axios.post(
        `${handelStatusUrl}`,
        this.$qs.stringify(params)
      );
      if (res.code === 200) {
        this.isDialog = false;
        this.listData = [];
        this.getCreateWork();
      }
    },
    confirm() {
      this.handelStatusUrl();
    },
    toHandel(item) {
      this.isDialog = true;
      this.id = item.id;
    },
    returnTime(time) {
      if (time) {
        return moment(time).format("HH:mm");
      }
    },
    onLoad() {
      this.getCreateWork();
    },
    getCreateWork() {
      // this.listData = [];
      let params = {
        userId: this.userId,
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
      };
      this.$axios.get(`${selectMyApplyUrl}`, { params }).then((res) => {
        if (res.code === 200) {
          if (res.data.pages <= res.data.current) {
            this.finished = true;
          } else {
            this.$refs.list._data.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          res.data.records.forEach((item) => {
            this.listData.push(item);
          });
        } else {
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.myEntrustChild {
  box-sizing: border-box;
  background: #fafafa;
  min-height: 100%;
  padding: 46px 32px;
  .item {
    // height: 602px;
    background: #ffffff;
    box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
    border-radius: 16px;
    margin-bottom: 46px;
    .oItem {
      padding: 48px 32px 24px;
      display: flex;
      justify-content: space-between;
      // align-items: center;
      &:not(:last-child) {
        border-bottom: 2px solid rgba(0, 0, 0, 0.04);
      }
      &.flex {
        flex-direction: column;
        .oItem-l {
          margin-bottom: 16px;
        }
      }
      .oItem-l {
        font-size: 28px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
        line-height: 40px;
      }
      .oItem-r {
        font-size: 26px;
        color: rgba(0, 0, 0, 0.5);
        line-height: 40px;
      }

      .te {
        font-size: 28px;
        font-weight: bold;
        color: #007eff;
        line-height: 40px;
      }
      .yellow {
        color: #ffb24b;
      }
      .grey {
        color: #a8a8a8;
      }
    }
    .first {
      padding: 14px;
      font-size: 26px;
      font-weight: 400;
      color: #f7b500;
      line-height: 40px;
      .te {
        font-weight: 400;
      }
    }
  }
  .courseDialog {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background: rgba(0, 0, 0, 0.5);
  }
  .courseDialog-c {
    position: absolute;
    width: 612px;
    // min-height: 324px;
    // max-height: 624px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 0px 42px 0px rgba(66, 114, 153, 0.1);
    border-radius: 21px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    .course-t {
      padding: 26px;
      font-size: 36px;
      font-weight: 600;
      color: #007eff;
      line-height: 50px;
    }
    .course-b {
      padding: 0 0 20px;
      overflow: auto;
      min-height: 300px;
      max-height: 500px;
    }
    .list {
      display: flex;
      border: 2px solid rgba(0, 0, 0, 0.06);
      .list-l {
        flex: 1;
        flex-shrink: 0;
        padding: 14px 16px;
        box-sizing: border-box;
      }
      .list-r {
        flex: 1;
        flex-shrink: 0;
        padding: 14px 16px;
        box-sizing: border-box;
      }
      .list-l {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-weight: 400;
        color: #151c39;
        line-height: 34px;
        border-right: 2px solid rgba(0, 0, 0, 0.06);
      }
      .list-r {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-weight: 400;
        color: #151c39;
        line-height: 34px;
      }
    }
    .courseDialog-b {
      width: 80px;
      height: 80px;
      position: absolute;
      bottom: -100px;
      margin: auto;
      left: 0;
      right: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .v-dialog {
    /deep/ .van-dialog__content {
      padding: 10px 0;
      .van-radio-group {
        justify-content: center;
      }
    }
  }
}
</style>
